(document.querySelectorAll('.notification .delete')).forEach((deleteBtn) => {
  const notification = deleteBtn.parentNode;
  deleteBtn.addEventListener('click', () => {
    notification.remove();
  });
  setTimeout(() => {
    notification.remove();
  },10000);
  document.addEventListener('click', (e) => {
    if(!e.target.closest('.notification')) {
      notification.remove();
    }
  })
});
